import { IDropdownOption } from "@fluentui/react";
import { CountryCodeToFriendlyNameMapping, ModeToFriendlyNameMapping, DocumentTypeToFriendlyNameMapping, PartnerIdToFriendlyNameMapping, StatusToFriendlyNameMapping, StringDictionary, SupplierIdToFriendlyNameMapping, SalesMotionToFriendlyNameMapping } from "../../utils/FriendlyNameMappings"

export function getOptionsFromDimension(item: any, showCount: boolean): IDropdownOption[] {
    var dropDownOptions: StringDictionary = {}
    if (item.dimension === "eInvPartnerId") {
        dropDownOptions = PartnerIdToFriendlyNameMapping
    }
    else if (item.dimension === "countryCode") {
        dropDownOptions = CountryCodeToFriendlyNameMapping
    }
    else if (item.dimension === "supplier") {
        dropDownOptions = SupplierIdToFriendlyNameMapping
    }
    else if (item.dimension === "mode") {
        dropDownOptions = ModeToFriendlyNameMapping
    }
    else if (item.dimension === "documentType") {
        dropDownOptions = DocumentTypeToFriendlyNameMapping
    }
    else if (item.dimension === "salesMotion") {
        dropDownOptions = SalesMotionToFriendlyNameMapping
    }
    if (showCount) {
        return Object.entries<number>(item.aggregatedTotals).map(([key, value]) => {
            return { key: key, text: `${!!Object.keys(dropDownOptions).length ? dropDownOptions[key] : key} (${value})` };
        });
    }
    else {
        return Object.entries<number>(item.aggregatedTotals).map(([key, value]) => {
            return { key: key, text: `${!!Object.keys(dropDownOptions).length ? dropDownOptions[key] : key}` };
        });
    }
}

export function getKeyFriendlyName(list: string, key: string) {
    var keyMappings: StringDictionary = {}
    if (list === "status") {
        keyMappings = StatusToFriendlyNameMapping
    }
    else if (list === "mode") {
        keyMappings = ModeToFriendlyNameMapping
    }
    else if (list === "documentType") {
        keyMappings = DocumentTypeToFriendlyNameMapping
    }
    return !!Object.keys(keyMappings).length ? keyMappings[key] : key
}