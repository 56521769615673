import React, { useEffect, useState } from 'react';
import { loadTheme, ThemeProvider } from '@fluentui/react';
import { CoherenceV9DarkTheme, CoherenceV9LightTheme } from '@coherence-design-system/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard';
import Header from '../components/header/Header';
import Navigation from '../components/navigation/Navigation';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import UnauthorizedLanding from '../pages/UnauthorizedLandingPage/UnauthorizedLanding';
import { getAppStyles } from './App.styles';
import Latency from '../pages/latency/latency';
import Reports from '../pages/reports/reports';
import { AuthenticationResult } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode'
import Configuration from "../config/einvoicing-configuration";


function App() {

    const [darkTheme, setDarkTheme] = React.useState<boolean>(false);

    const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState('');

    async function getAccessToken() {
        if (accessToken === '') {
            const request = {
                ...Configuration.msalTokenRequest,
                account: accounts[0]
            };
            const result: AuthenticationResult = await instance.acquireTokenSilent(request);
            setAccessToken(result.idToken);
            return result.idToken
        }
        return accessToken;
    }

    function useAdminCheck() {
        useEffect(() => {
            async function startLatency() {
                if (!ignore) {
                    try {
                        const token = await getAccessToken();
                        const decodedToken: any = jwtDecode(token);
                        const roles: string[] = decodedToken['roles'];
                        if (roles.includes('EInvoicingDashboard.Admin')) {
                            setIsAdmin(true);
                        }
                        else {
                            setIsAdmin(false);
                        }
                    }
                    catch {
                    }
                }
            }

            let ignore = false;
            startLatency();
            return () => {
                ignore = true;
            }
        }, []);
    }

    useAdminCheck();
    // Load the coherence theme to theme all components in app.

    loadTheme(darkTheme ? CoherenceV9DarkTheme : CoherenceV9LightTheme);

    const appStyles = getAppStyles();

    return (
        <ThemeProvider theme={darkTheme ? CoherenceV9DarkTheme : CoherenceV9LightTheme}>
            <AuthenticatedTemplate>
                <div className={appStyles.primaryContainer}>
                    <Header setDarkTheme={setDarkTheme} darkTheme={darkTheme} />
                    <BrowserRouter>
                        <Navigation isAdmin={isAdmin} />
                        <main id='main' tabIndex={-1} className={appStyles.root}>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/latency" element={<Latency />} />
                                {isAdmin && <Route path="/reports" element={<Reports />} />}
                            </Routes>
                        </main>
                    </BrowserRouter>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UnauthorizedLanding />
            </UnauthenticatedTemplate>
        </ThemeProvider>
    );
}

export default App;
