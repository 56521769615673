import { CoherenceNav } from "@coherence-design-system/controls";
interface NavigationProps {
    isAdmin: boolean;
}

const _isSelected = (location: Location, path: string): boolean => {
    const locationPath = location.protocol + "//" + location.host + path;
  
    return location.href === locationPath;
  };


function Navigation(props: NavigationProps) {

    if (props.isAdmin) {
        return (
            <CoherenceNav
                appName="Modern E-Invoicing"
                groups={[
                    {
                        key: "Left navigation menu",
                        links: [
                            {
                                name: "Home",
                                key: "home_navigation",
                                ariaLabel: "Home",
                                icon: "Home",
                                href: "/",
                                isSelected: _isSelected(window.location, "/"),
                            },
                            {
                                name: "Latency / SLA",
                                key: "SLAs and Latencies",
                                ariaLabel: "Latency",
                                icon: "AnalyticsView",
                                href: "/latency",
                                isSelected: _isSelected(window.location, "/latency"),
                            },
                            {
                                name: "Reports",
                                key: "Reports",
                                ariaLabel: "Reports",
                                icon: "ReportWarning",
                                href: "/reports",
                                isSelected: _isSelected(window.location, "/reports"),
                            }
                        ],
                    },
                ]}
                defaultIsNavCollapsed={true}
            />
        );
    }
    else {
        return (
            <CoherenceNav
                appName="Modern E-Invoicing"
                groups={[
                    {
                        key: "Left navigation menu",
                        links: [
                            {
                                name: "Home",
                                key: "home_navigation",
                                ariaLabel: "Home",
                                icon: "Home",
                                href: "/",
                                isSelected: _isSelected(window.location, "/"),
                            },
                            {
                                name: "Latency / SLA",
                                key: "SLAs and Latencies",
                                ariaLabel: "Latency",
                                icon: "AnalyticsView",
                                href: "/latency",
                                isSelected: _isSelected(window.location, "/latency"),
                            }
                        ],
                    },
                ]}
                defaultIsNavCollapsed={true}
            />
        );
    }
}

export default Navigation;