import EInvoicingConfiguration from "./config.type";
import { Configuration as DevConfiguration } from './einvoicing-configuration.DEV';
import { Configuration as IntConfiguration } from './einvoicing-configuration.INT';
import { Configuration as AircapiConfiguration } from './einvoicing-configuration.AIRCAPI';
import { Configuration as ProdConfiguration } from './einvoicing-configuration.PROD';

const Configuration: EInvoicingConfiguration = process.env.REACT_APP_EINVOICING_ENVIRONMENT === null ? DevConfiguration :
                                               process.env.REACT_APP_EINVOICING_ENVIRONMENT === 'INT' ? IntConfiguration :
                                               process.env.REACT_APP_EINVOICING_ENVIRONMENT === 'AIRCAPI' ? AircapiConfiguration :
                                               process.env.REACT_APP_EINVOICING_ENVIRONMENT === 'PROD' ? ProdConfiguration : DevConfiguration;

export default Configuration;