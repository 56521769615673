import { CoherenceHeader } from "@coherence-design-system/controls";
import { useMsal } from '@azure/msal-react';
import { Toggle } from "@fluentui/react";

interface HeaderProps {
    setDarkTheme: React.Dispatch<React.SetStateAction<boolean>>
    darkTheme: boolean
}

function Header(props: HeaderProps) {
    const { accounts, instance } = useMsal();
    return (
        <CoherenceHeader
            headerLabel="e-Invoicing Dashboard"
            appNameSettings={{
                label: 'e-Invoicing Dashboard'
            }}
            farRightSettings={{
                helpSettings: {
                    panelSettings: {
                        onClick: clickHelp,
                        titleText: "Help",
                        body: getHelpBody(),
                    },
                },
                profileSettings: {
                    panelSettings: {
                        fullName: accounts[0]?.name,
                        emailAddress: accounts[0]?.username,
                        imageUrl: undefined,
                        logOutLink: '#',
                        onLogOut: () => {
                            return instance.logoutRedirect();
                        },

                    }
                },
                settingsSettings: {
                    panelSettings: {
                        body: getSettingsBody()
                    }
                }
            }}
        />
    );

    function clickHelp() {
        console.log("You clicked Help!");
    }

    function getHelpBody() {
        return (
            <div>
                <br />
                <br />
                <div>Contact <a href="mailto: ctbdevs@microsoft.com">support</a> for further assistance</div>
                <br />
                <br />
                <div><a href="http://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" rel="noreferrer">Privacy & Cookies</a></div>
            </div>
        );
    };
    function getSettingsBody() {
        return (<Toggle label="Use Theme" onText="Dark" defaultChecked={props.darkTheme} offText="Light" onChange={onToggleChange} />);
    };

    function onToggleChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        props.setDarkTheme(checked ?? false)
    }
}
export default Header;