import { useMsal } from '@azure/msal-react';
import { DefaultButton } from '@fluentui/react';
import React from 'react';
import Configuration from '../../config/einvoicing-configuration';
function UnauthorizedLanding() {
    const { instance } = useMsal();

    const handleLogin = (loginType: string) => {
        if (loginType === "redirect") {
            instance.loginRedirect(Configuration.msalTokenRequest).catch(e => {
                console.log(e);
            });
        }
    }
    handleLogin("redirect");
    return (
        <div></div>
  );
}

export default UnauthorizedLanding;