import { IStyle, mergeStyleSets } from "@fluentui/react";

export const getAppStyles = () =>
  mergeStyleSets({
    root: {
      marginLeft: "48px",
      marginRight: "0",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      position: "relative",
    } as IStyle,
    primaryContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "100vh",
    } as IStyle,
  });
