import { DatePicker, Dropdown, SearchBox, IDropdownOption } from "@fluentui/react";
import "./FilterGrid.css";
import { getOptionsFromDimension, getKeyFriendlyName } from "../../pages/dashboard/DocumentSearchFilterOptions";
import { FilterKey } from "../../pages/dashboard/DocumentSearchFilters";
import { ActionButton } from '@fluentui/react/lib/Button';
import { DayOfWeek, defaultDatePickerStrings } from '@fluentui/react';
import { TimePicker } from '@fluentui/react';
import { IComboBoxStyles, IComboBox } from '@fluentui/react';
import { DonutChart, IChartProps } from '@fluentui/react-charting';
import { IChartDataPointExtended } from '../../pages/dashboard/IChartDataPointExtended';
import { ChartTitleFriendlyNames } from "../../utils/FriendlyNameMappings";
import React from 'react';
import { FilterGridProps } from "./FilterGridProps";
import { getDefaultSearchParams } from "../../controller/dashboardController";
import { getTheme } from '@fluentui/react/lib/Styling';

function FilterGrid(props: FilterGridProps) {
    const [selectedKeys, setSelectedKeys] = React.useState<(string[] | number[] | null)[]>([null, null, null, null, null]);

    const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined, filterKey: FilterKey, index: number): void => {
        if (item) {
            let filterVal = `${item.key}`; //coerce value into string
            if (item.selected) {
                props.setSearchParams({
                    ...props.searchParams,
                    filters: {
                        ...props.searchParams.filters,
                        [filterKey]: [...props.searchParams.filters[filterKey], filterVal]
                    }
                })
                if (selectedKeys[index] != null) {
                    const array = selectedKeys[index] as (string[])
                    setSelectedKeys(Object.assign([...selectedKeys], { [index]: [...array, item.key] }))
                }
                else {
                    setSelectedKeys(Object.assign([...selectedKeys], { [index]: [item.key] }))
                }
            }
            else {
                props.setSearchParams({
                    ...props.searchParams,
                    filters: {
                        ...props.searchParams.filters,
                        [filterKey]: props.searchParams.filters[filterKey].filter((el) => el !== filterVal)
                    }
                })
                if ((selectedKeys[index]?.length ?? 0) > 1) {
                    const array = selectedKeys[index] as (string[])
                    const indexToRemove = array.indexOf(item.key as string);
                    array.splice(indexToRemove, 1)
                    setSelectedKeys(Object.assign([...selectedKeys], { [index]: [...array] }))
                }
                else {
                    setSelectedKeys(Object.assign([...selectedKeys], { [index]: null }))
                }
            }
        }
    };
    const onSearchDropdownChange = (item: IDropdownOption | undefined): void => {
        props.setExactSearch(item?.data);
    };

    function getIChartDataPoint(list: string, aggregatedTotals: any): IChartDataPointExtended[] {
        return Object.entries<number>(aggregatedTotals).map(([key, value], index: number) => {
            const friendlyKey = getKeyFriendlyName(list, key)
            return { legend: friendlyKey, data: value, color: getColor(list, friendlyKey), key: key };
        });
    }

    const theme = getTheme();
    const _colors: { [key: string]: { [key: string]: string } } =
    {
        'status':
        {
            "In Progress": theme.palette.blue,
            "Failed": theme.palette.red,
            "Succeeded": theme.palette.green,
            "Warning": theme.palette.orange,
            "Cancel Succeeded": theme.palette.greenDark,
            "Cancel Failed": theme.palette.redDark,
            "Cancel In Progress": theme.palette.blueDark
        },
        'mode': {
            "UAT": theme.palette.blue,
            "Live": theme.palette.green,
            "Test": theme.palette.yellow
        },
        'documentType': {
            'Invoice': theme.palette.magenta,
            'Credit Note': theme.palette.orange,
            'Payment Receipt': theme.palette.blue,
            'Debit Note': theme.palette.yellow
        },
        'channel': {
            'commercial': theme.palette.teal,
            'consumer': theme.palette.purple
        }
    }

    // Temp method to assign color. Need to define fixed colors
    function getColor(list: string, key: string): string {
        return _colors[list][key] ?? theme.palette.blue;
    }

    const handleDataClick = (data: IChartDataPointExtended) => {
        props.setSearchParams({
            ...props.searchParams,
            filters: {
                ...props.searchParams.filters,
                [data.chartTitle ?? '']: [data.key]
            }
        })
    };

    function mapToFacets(raw: any[]): IChartProps[] {
        return raw.map(row => {

            var chartData: IChartDataPointExtended[] = getIChartDataPoint(row.dimension, row.aggregatedTotals);
            chartData = chartData.map((d) => (
                {
                    ...d,
                    chartTitle: row.dimension,
                }))
            chartData = chartData.map((d) => (
                {
                    ...d,
                    onClick: () => handleDataClick(d)
                }))

            return {
                chartTitle: row.dimension,
                chartData: chartData
            };
        });
    };

    function resetSearchData() {
        props.setSearchParams(getDefaultSearchParams());
        setSelectedKeys([null, null, null, null]);
    }

    function setEndTimeToNow() {
        props.setSearchParams({ ...props.searchParams, endTime: new Date() })
    }

    const datePickerStyles: Partial<IComboBoxStyles> = {
        root: {
            width: '35%',
            float: 'left',
            marginLeft: '10%'
        }
    };
    const timePickerStyles: Partial<IComboBoxStyles> = {
        optionsContainerWrapper: {
            height: '500px',
        },
        root: {
            width: '35%',
            float: 'left'
        }
    };
    function convertUTCDateToLocalDate(date: Date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }
    function endTimeChanged(_: React.FormEvent<IComboBox>, date: Date) {
        var newDate = props.searchParams.endTime
        if (props.useUTC) {
            var utcDate = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
            utcDate.setHours(date.getHours())
            utcDate.setMinutes(date.getMinutes())
            utcDate.setSeconds(0, 0)
            newDate = convertUTCDateToLocalDate(utcDate);
        }
        else {
            newDate.setHours(date.getHours())
            newDate.setMinutes(date.getMinutes())
            newDate.setSeconds(0, 0)
        }
        props.setSearchParams({ ...props.searchParams, endTime: newDate });
    }

    function endDateChanged(date: Date | null | undefined) {
        var newDate = date
        if (newDate && newDate !== null) {
            if (props.useUTC) {
                newDate = convertUTCDateToLocalDate(newDate);
            }
            newDate.setHours(props.searchParams.endTime.getHours())
            newDate.setMinutes(props.searchParams.endTime.getMinutes())
            props.setSearchParams({ ...props.searchParams, endTime: newDate });
        }
    }

    function startDateChanged(date: Date | null | undefined) {
        var newDate = date
        if (newDate && newDate !== null) {
            if (props.useUTC) {
                newDate = convertUTCDateToLocalDate(newDate);
            }
            newDate.setHours(props.searchParams.startTime.getHours())
            newDate.setMinutes(props.searchParams.startTime.getMinutes())
            props.setSearchParams({ ...props.searchParams, startTime: newDate });
        }
    }

    function startTimeChanged(_: React.FormEvent<IComboBox>, date: Date) {
        var newDate = props.searchParams.startTime
        if (props.useUTC) {
            var utcDate = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
            utcDate.setHours(date.getHours())
            utcDate.setMinutes(date.getMinutes())
            newDate = convertUTCDateToLocalDate(utcDate);
            utcDate.setSeconds(0, 0)
        }
        else {
            newDate.setHours(date.getHours())
            newDate.setMinutes(date.getMinutes())
            newDate.setSeconds(0, 0)
        }
        props.setSearchParams({ ...props.searchParams, startTime: newDate });
    }

    const buildDonutCharts: string[] = [
        'status',
        'documentType',
        'channel',
        'mode'
    ];

    const buildDropDowns: string[] = [
        'eInvPartnerId',
        'countryCode',
        'supplier',
        'errorCode',
        'salesMotion'
    ];

    const searchDropDownOptions: IDropdownOption<boolean>[] = [
        {
            key: 'Exact Search',
            text: 'Exact Search',
            data: true,
            selected: true
        },
        {
            key: 'WildCard Search',
            text: 'WildCard Search',
            data: false
        }
    ]
    function GetDateValue(useUTC: boolean, date: Date): Date {
        return useUTC ? new Date(date.getTime() + date.getTimezoneOffset() * 60000) : date;
    }

    return (
        <div className="einv-search-bar-container">
            <div className="einv-main-search-container">
                <SearchBox className="einv-search-box"
                    placeholder="Search for document ids, partners, customer ids, etc.."
                    onSearch={(newSearch) => props.setSearchParams({ ...props.searchParams, searchText: newSearch })} />
                <Dropdown className='einv-searchDropDown' onChange={(ev, it) => onSearchDropdownChange(it)} options={searchDropDownOptions} dropdownWidth='auto' />
                <ActionButton onClick={resetSearchData} style={{ float: 'right' }} iconProps={{ iconName: 'RevToggleKey' }}>Reset Search</ActionButton>
            </div>
            <div className='einv-filters-container'>
                <div className='einv-datePicker-container'>
                    <div className='einv-dateTimeCombo'>
                        <DatePicker
                            isRequired
                            styles={datePickerStyles}
                            firstDayOfWeek={DayOfWeek.Monday}
                            placeholder="Select a Start Date..."
                            ariaLabel="Select a start date"
                            strings={defaultDatePickerStrings}
                            label="Start Date"
                            value={GetDateValue(props.useUTC, props.searchParams.startTime)}
                            onSelectDate={startDateChanged}
                        />
                        <TimePicker
                            styles={timePickerStyles}
                            autoComplete="on"
                            value={GetDateValue(props.useUTC, props.searchParams.startTime)}
                            aria-label="Select Start Date Time"
                            placeholder="Select Start Date Time..."
                            label="Start Time"
                            onChange={startTimeChanged}
                            useHour12={false}
                            showSeconds={false}
                            dateAnchor={GetDateValue(props.useUTC, props.searchParams.startTime)}
                        />
                    </div>
                    <div className='einv-dateTimeCombo'>
                        <DatePicker
                            isRequired
                            styles={datePickerStyles}
                            firstDayOfWeek={DayOfWeek.Monday}
                            placeholder="Select a End Date..."
                            ariaLabel="Select a end date"
                            strings={defaultDatePickerStrings}
                            label="End Date"
                            value={GetDateValue(props.useUTC, props.searchParams.endTime)}
                            onSelectDate={endDateChanged}
                        />
                        <TimePicker
                            styles={timePickerStyles}
                            autoComplete="on"
                            value={GetDateValue(props.useUTC, props.searchParams.endTime)}
                            aria-label="Select End Date Time"
                            placeholder="Select End Date Time..."
                            label="End Time"
                            onChange={endTimeChanged}
                            useHour12={false}
                            showSeconds={false}
                            dateAnchor={GetDateValue(props.useUTC, props.searchParams.endTime)}
                        />
                        <ActionButton onClick={setEndTimeToNow} className='einv-dateTimeNowButton'>Now</ActionButton>
                    </div>
                    {buildDropDowns.map(a => props.facetResults.filter(f => f.dimension == a)[0]).map((item: any, index: number) => (
                        <Dropdown className="einv-dropdown" label={ChartTitleFriendlyNames[item.dimension]} selectedKeys={selectedKeys[index]} onChange={(ev, it) => onDropdownChange(ev, it, item.dimension, index)} options={getOptionsFromDimension(item, true)} multiSelect dropdownWidth='auto' />
                    ))}
                </div>
                <div className='einv-donut-charts-container'>
                    {mapToFacets(buildDonutCharts.map(a => props.facetResults.filter(f => f.dimension == a)[0])).map((item: IChartProps) => (
                        <div key={item.chartTitle} style={{ margin: 'auto', textAlign: 'center' }} >
                            <DonutChart
                                data={item}
                                innerRadius={25}
                                hideLegend={false}
                                enabledLegendsWrapLines={true}
                                height={120}
                                chartLabel={item.chartTitle}
                            />
                            <b>{ChartTitleFriendlyNames[item.chartTitle ?? '']}</b>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FilterGrid;