import React, { CSSProperties, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Configuration from '../../config/einvoicing-configuration';
import { AuthenticationResult } from '@azure/msal-common';
import { CoherenceLoading } from '@coherence-design-system/controls';
import { ActionButton, DatePicker, DayOfWeek, defaultDatePickerStrings, Toggle } from '@fluentui/react';
import { TimePicker } from '@fluentui/react';
import { IComboBoxStyles, IComboBox } from '@fluentui/react';
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { ErrorStateComponent } from '@coherence-design-system/controls'
import { getOptionsFromDimension } from "../../pages/dashboard/DocumentSearchFilterOptions";
import { ChartTitleFriendlyNames, CountryCodeToFriendlyNameMapping, ModeToFriendlyNameMapping, SupplierIdToFriendlyNameMapping } from "../../utils/FriendlyNameMappings";
import { FilterKey } from '../dashboard/DocumentSearchFilters';
import { IChartProps, LineChart } from '@fluentui/react-charting';
import { constructDocumentSearchUrl, constructLatencyUrl, constructPostRequest, getDefaultLatencyParams } from '../../controller/dashboardController';
import { SearchParams } from './SearchParams';
import { CountrySupplierLatencies } from './CountrySupplierLatencies';
import { LatencyPoint } from './LatencyPoint';
import { SLAPoint } from './SLAPoint';
import './latency.css'

interface LatencyProps { }

function Latency(props: LatencyProps) {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState('');
    const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(true);
    const [isError, setIsError]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);
    const [searchParams, setSearchParams]: [SearchParams, React.Dispatch<React.SetStateAction<SearchParams>>] = useState(getDefaultLatencyParams());
    const [facets, setFacets]: [any[], React.Dispatch<React.SetStateAction<any[]>>] = useState<any[]>([]);
    const [latency, setLatency]: [CountrySupplierLatencies[], React.Dispatch<React.SetStateAction<CountrySupplierLatencies[]>>] = useState<CountrySupplierLatencies[]>([]);
    const [useUTC, setUseUTC]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(true);

    const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined, filterKey: FilterKey, index: number): void => {
        if (item) {
            let filterVal = `${item.key}`;
            setSearchParams({
                ...searchParams,
                filters: {
                    ...searchParams.filters,
                    [filterKey]: [filterVal]
                }
            })
        }
    };


    async function getAccessToken() {
        if (accessToken === '') {
            const request = {
                ...Configuration.msalTokenRequest,
                account: accounts[0]
            };
            const result: AuthenticationResult = await instance.acquireTokenSilent(request);
            setAccessToken(result.idToken);
            return result.idToken
        }
        return accessToken;
    }

    function mapLatency(resultJson: any[]): CountrySupplierLatencies[] {
        const latencies: CountrySupplierLatencies[] = []
        resultJson.forEach(row => {
            let existingLatencyIndex = latencies.findIndex(item => item.countryCode === CountryCodeToFriendlyNameMapping[row.countryCode] && item.supplier === SupplierIdToFriendlyNameMapping[row.supplier] && item.mode === ModeToFriendlyNameMapping[row.mode])
            if (existingLatencyIndex === -1) {
                const latency: CountrySupplierLatencies = {
                    countryCode: CountryCodeToFriendlyNameMapping[row.countryCode],
                    eInvLatencies: [],
                    partnerLatencies: [],
                    slaPercentages: [],
                    supplierLatencies: [],
                    supplier: SupplierIdToFriendlyNameMapping[row.supplier],
                    mode: ModeToFriendlyNameMapping[row.mode]
                }
                existingLatencyIndex = latencies.push(latency) - 1
            }
            latencies[existingLatencyIndex].slaPercentages.push({
                slaPercentage: row.slaPercentage,
                timeStamp: row.timeStamp
            })
            latencies[existingLatencyIndex].eInvLatencies.push({
                averageLatency: row.averageLatency / 1000,
                maxLatency: row.maxLatency / 1000,
                minLatency: row.minLatency / 1000,
                _95thPercentileLatency: row.percentile95Latency / 1000,
                _99thPercentileLatency: row.percentile99Latency / 1000,
                timeStamp: row.timeStamp
            })

            latencies[existingLatencyIndex].partnerLatencies.push({
                averageLatency: row.averagePartnerLatency / 1000,
                maxLatency: row.maxPartnerLatency / 1000,
                minLatency: row.minPartnerLatency / 1000,
                _95thPercentileLatency: row.percentile95PartnerLatency / 1000,
                _99thPercentileLatency: row.percentile99PartnerLatency / 1000,
                timeStamp: row.timeStamp
            })

            latencies[existingLatencyIndex].supplierLatencies.push({
                averageLatency: row.averageSupplierLatency / 1000,
                maxLatency: row.maxSupplierLatency / 1000,
                minLatency: row.minSupplierLatency / 1000,
                _95thPercentileLatency: row.percentile95SupplierLatency / 1000,
                _99thPercentileLatency: row.percentile99SupplierLatency / 1000,
                timeStamp: row.timeStamp
            })
        });
        return latencies
    }

    function useLatency(params: SearchParams) {
        useEffect(() => {
            const latencyUrl: string = constructLatencyUrl(Configuration.eventServiceUrl, params.startTime, params.endTime);
            const searchUrl: string = constructDocumentSearchUrl(Configuration.eventServiceUrl, params.startTime, params.endTime, "*");
            async function startLatency() {
                if (!ignore) {
                    try {
                        const requestParams = constructPostRequest(params.filters, await getAccessToken());
                        const result = await fetch(latencyUrl, requestParams);
                        const facetParams = constructPostRequest(params, await getAccessToken());
                        const facetResults = await fetch(searchUrl, facetParams);
                        if (facetResults !== undefined && facetResults.ok && facets.length === 0) {
                            const facetJson = await facetResults.json()
                            setFacets(facetJson.facets)
                        }
                        if (result !== undefined && result.ok) {
                            const resultJson = await result.json()
                            setLatency(mapLatency(resultJson))
                            setIsLoading(false);
                        }
                        else {
                            setIsLoading(false);
                            setIsError(true);
                        }
                    }
                    catch {
                        setIsLoading(false);
                        setIsError(true);
                    }
                }
            }

            let ignore = false;
            startLatency();
            return () => {
                ignore = true;
            }
        }, [params]);
    }

    const datePickerStyles: Partial<IComboBoxStyles> = {
        root: {
            width: '40%',
            float: 'left',
            marginLeft: '10%'
        }
    };
    const timePickerStyles: Partial<IComboBoxStyles> = {
        optionsContainerWrapper: {
            height: '500px',
        },
        root: {
            width: '40%',
            float: 'left'
        }
    };

    useLatency(searchParams);

    function convertUTCDateToLocalDate(date: Date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }
    function endTimeChanged(_: React.FormEvent<IComboBox>, date: Date) {
        var newDate = searchParams.endTime
        if (useUTC) {
            var utcDate = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
            utcDate.setHours(date.getHours())
            utcDate.setMinutes(date.getMinutes())
            newDate = convertUTCDateToLocalDate(utcDate);
        }
        else {
            newDate.setHours(date.getHours())
            newDate.setMinutes(date.getMinutes())
        }
        setSearchParams({ ...searchParams, endTime: newDate });
    }

    function endDateChanged(date: Date | null | undefined) {
        var newDate = date
        if (newDate && newDate !== null) {
            if (useUTC) {
                newDate = convertUTCDateToLocalDate(newDate);
            }
            newDate.setHours(searchParams.endTime.getHours())
            newDate.setMinutes(searchParams.endTime.getMinutes())
            setSearchParams({ ...searchParams, endTime: newDate });
        }
    }

    function startDateChanged(date: Date | null | undefined) {
        var newDate = date
        if (newDate && newDate !== null) {
            if (useUTC) {
                newDate = convertUTCDateToLocalDate(newDate);
            }
            newDate.setHours(searchParams.startTime.getHours())
            newDate.setMinutes(searchParams.startTime.getMinutes())
            setSearchParams({ ...searchParams, startTime: newDate });
        }
    }

    function startTimeChanged(_: React.FormEvent<IComboBox>, date: Date) {
        var newDate = searchParams.startTime
        if (useUTC) {
            var utcDate = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
            utcDate.setHours(date.getHours())
            utcDate.setMinutes(date.getMinutes())
            newDate = convertUTCDateToLocalDate(utcDate);
        }
        else {
            newDate.setHours(date.getHours())
            newDate.setMinutes(date.getMinutes())
        }
        setSearchParams({ ...searchParams, startTime: newDate });
    }


    const buildDropDowns: string[] = [
        'eInvPartnerId',
        'countryCode',
        'supplier',
        'documentType',
        'mode'
    ];

    function getCalloutDateString(date: Date): string {
        let isoString:string = date.toISOString();
        let [dateString, timeString] = isoString.split('T');
        [timeString] = timeString.split('.');
        return `${dateString} ${timeString}`;
    }

    function getLineChartData(eInvLatencies: LatencyPoint[], chartTitle: string): IChartProps {
        return {
            chartTitle: chartTitle,
            lineChartData: [
                {
                    legend: "Average",
                    data: eInvLatencies.map((latency: LatencyPoint) => { return { x: GetDateValue(useUTC, new Date(latency.timeStamp)), y: latency.averageLatency, xAxisCalloutData: getCalloutDateString(GetDateValue(useUTC, new Date(latency.timeStamp))) } }),
                },
                {
                    legend: "Max",
                    data: eInvLatencies.map((latency: LatencyPoint) => { return { x: GetDateValue(useUTC, new Date(latency.timeStamp)), y: latency.maxLatency, xAxisCalloutData: getCalloutDateString(GetDateValue(useUTC, new Date(latency.timeStamp))) } })
                },
                {
                    legend: "Min",
                    data: eInvLatencies.map((latency: LatencyPoint) => { return { x: GetDateValue(useUTC, new Date(latency.timeStamp)), y: latency.minLatency, xAxisCalloutData: getCalloutDateString(GetDateValue(useUTC, new Date(latency.timeStamp)))} })
                },
                {
                    legend: "95th Perc.",
                    data: eInvLatencies.map((latency: LatencyPoint) => { return { x: GetDateValue(useUTC, new Date(latency.timeStamp)), y: latency._95thPercentileLatency, xAxisCalloutData: getCalloutDateString(GetDateValue(useUTC, new Date(latency.timeStamp))) } })
                },
                {
                    legend: "99th Perc.",
                    data: eInvLatencies.map((latency: LatencyPoint) => { return { x: GetDateValue(useUTC, new Date(latency.timeStamp)), y: latency._99thPercentileLatency, xAxisCalloutData: getCalloutDateString(GetDateValue(useUTC, new Date(latency.timeStamp))) } })
                },
            ]
        }
    }
    function getSLALineChartData(slaNumbers: SLAPoint[], chartTitle: string): IChartProps {
        return {
            chartTitle: chartTitle,
            lineChartData: [{
                legend: 'In SLA %',
                data: slaNumbers.map((num: SLAPoint) => { return { x: new Date(num.timeStamp), y: num.slaPercentage } })
            }]
        }
    }

    function onTimeZoneChange(event: React.MouseEvent<HTMLElement>, checked?: boolean) {
        setUseUTC(checked ?? false)
        refresh()
    }
    function refresh() {
        setSearchParams({ ...searchParams });
    }

    function GetDateValue(useUTC: boolean, date: Date): Date {
        return useUTC ? new Date(date.getTime() + date.getTimezoneOffset() * 60000) : date;
    }

    if (isLoading) {
        return <CoherenceLoading primaryText='Loading Data...' />
    }
    else if (!isError) {

        return (
            <div>
                <h1>Latencies and SLA</h1>
                <div className='latency-container'>
                    <div className='latency-filterContainer'>
                        <div className='latency-datetimecombo'>
                            <DatePicker
                                isRequired
                                styles={datePickerStyles}
                                firstDayOfWeek={DayOfWeek.Monday}
                                placeholder="Select a Start Date..."
                                ariaLabel="Select a start date"
                                strings={defaultDatePickerStrings}
                                label="Start Date"
                                value={GetDateValue(useUTC, searchParams.startTime)}
                                onSelectDate={startDateChanged}
                            />
                            <TimePicker
                                styles={timePickerStyles}
                                autoComplete="on"
                                value={GetDateValue(useUTC, searchParams.startTime)}
                                aria-label="Select Start Date Time"
                                placeholder="Select Start Date Time..."
                                label="Start Time"
                                onChange={startTimeChanged}
                                useHour12={false}
                                showSeconds={false}
                                dateAnchor={GetDateValue(useUTC, searchParams.startTime)}
                            />
                        </div>
                        <div className='latency-datetimecombo'>
                            <DatePicker
                                isRequired
                                styles={datePickerStyles}
                                firstDayOfWeek={DayOfWeek.Monday}
                                placeholder="Select a End Date..."
                                ariaLabel="Select a end date"
                                strings={defaultDatePickerStrings}
                                label="End Date"
                                value={GetDateValue(useUTC, searchParams.endTime)}
                                onSelectDate={endDateChanged}
                            />
                            <TimePicker
                                styles={timePickerStyles}
                                autoComplete="on"
                                value={GetDateValue(useUTC, searchParams.endTime)}
                                aria-label="Select End Date Time"
                                placeholder="Select End Date Time..."
                                label="End Time"
                                onChange={endTimeChanged}
                                useHour12={false}
                                showSeconds={false}
                                dateAnchor={GetDateValue(useUTC, searchParams.endTime)}
                            />
                        </div>
                    </div>
                    <div className='latency-dropdown-container'>

                        {buildDropDowns.map(a => facets.filter(f => f.dimension == a)[0]).map((item: any, index: number) => (
                            <Dropdown className="latency-dropdown" label={ChartTitleFriendlyNames[item.dimension]} onChange={(ev, it) => onDropdownChange(ev, it, item.dimension, index)} options={getOptionsFromDimension(item, false)} dropdownWidth='auto' />
                        ))}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Toggle label="Time Zone" onText="UTC" offText="Local" onChange={onTimeZoneChange} checked={useUTC} inlineLabel={true} />
                    <ActionButton iconProps={{ iconName: 'Refresh' }} title="Refresh" onClick={refresh}>Refresh</ActionButton>
                </div>
                <div >
                    {latency.map((item: CountrySupplierLatencies, index: number) => (
                        <div>
                            <h3>Latencies for country/region - {item.countryCode}, Supplier - {item.supplier}, Mode - {item.mode}</h3>
                            <div className='latency-chartcontainer'>
                                <div className='latency-chart'>
                                    <h4>EInv Latencies in seconds</h4>
                                    <LineChart data={getLineChartData(item.eInvLatencies, 'EInv Latencies')} chartLabel='EInv Latencies' height={300} allowMultipleShapesForPoints={true} />
                                </div>
                                <div className='latency-chart'>
                                    <h4>Partner E2E Latencies in seconds</h4>
                                    <LineChart data={getLineChartData(item.partnerLatencies, 'Partner E2E Latencies')} chartLabel='Partner E2E Latencies' height={300} allowMultipleShapesForPoints={true} />
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                                <div className='latency-chart'>
                                    <h4>Supplier Latencies in seconds</h4>
                                    <LineChart data={getLineChartData(item.supplierLatencies, 'Supplier Latencies')} chartLabel='Supplier Latencies' height={300} allowMultipleShapesForPoints={true} />
                                </div>
                                <div className='latency-chart'>
                                    <h4>SLA Percentages in seconds</h4>
                                    <LineChart data={getSLALineChartData(item.slaPercentages, 'SLA Percentages')} chartLabel='SLA Percentages' height={300} allowMultipleShapesForPoints={true} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    else {
        return (
            <ErrorStateComponent
                callToActionButton={{
                    ctaAriaLabel: 'Click here to refresh the page',
                    ctaHandler: function noRefCheck() {
                        window.location.reload();
                    },
                    ctaText: 'Refresh page'
                }}
                descriptionText="Check your internet connection, try refreshing the page or contact support."
                headingLevel="h2"
                headingText="There was an issue loading the page"
            />);
    }
}

export default Latency;

