import { DocumentSearchParams } from "../pages/dashboard/DocumentSearchParams";
import { SearchParams } from "../pages/latency/SearchParams";

export function constructGetDocumentEventsUrl(baseUrl: string, documentId: string, countryCode: string, parnterId: string , mode: string): string {
    let url = encodeURI(`${baseUrl}/dashboard/GetDocumentEvents/${documentId}/${countryCode}/${parnterId}/${mode}`);
    return url;
}

export function constructGetDocumentBlobsUrl(baseUrl: string, documentId: string, countryCode: string, partnerId: string, scenario:string,  mode: string): string {
    if (!mode) {
        mode = 'Test'
    }
    let url = encodeURI(`${baseUrl}/dashboard/documents/${mode}/${documentId}/${countryCode}/${partnerId}/${scenario}/blobs`);
    return url;
}

export function constructGetRequest(accessToken: string): RequestInit {
    return {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        credentials: "omit", // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer" // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url

    }
}


export function constructLatencyUrl(baseUrl: string, startTime: Date, endTime: Date): string {
    let url = encodeURI(`${baseUrl}/dashboard/GetLatency?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`);
    return url;
}

export function constructReportsUrl(baseUrl: string, mode: string): string {
    let url = encodeURI(`${baseUrl}/dashboard/GetReports?mode=${mode}`);
    return url;
}


export function constructSearchPostRequest(params: any, useExactSearch: boolean, accessToken: string): RequestInit {
    if (useExactSearch && params.searchText) {
        params = { ...params, searchText: `"${params.searchText}"`}
    }
    return {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(params), // body data type must match "Content-Type" header
    }
}

export function constructPostRequest(params: any, accessToken: string): RequestInit {
    return {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(params), // body data type must match "Content-Type" header
    }
}

export function constructDocumentSearchUrl(baseUrl: string, startTime: Date, endTime: Date, search?: string): string {
    let url = encodeURI(`${baseUrl}/dashboard/SearchDocuments?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`);
    if (search) {
        url += `&searchText=${search}`;
    }
    return url;
}


export function constructExportSearchUrl(baseUrl: string, startTime: Date, endTime: Date, search?: string): string {
    let url = encodeURI(`${baseUrl}/dashboard/ExportSearchDocuments?startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`);
    if (search) {
        url += `&searchText=${search}`;
    }
    return url;
}

export function getDefaultSearchParams(): DocumentSearchParams {

    const ONE_DAY_IN_MILLIS = 86400000;
    return {
        page: 0,
        pageSize: 25,
        endTime: new Date(),
        startTime: new Date(Date.now() - ONE_DAY_IN_MILLIS * 7),
        filters: {
            eInvPartnerId: [],
            channel: [],
            documentType: [],
            errorCode: [],
            mode: [],
            countryCode: [],
            supplier: [],
            status: [],
            salesMotion: []
        },
        searchText: "",
    }
}

export function getDefaultLatencyParams(): SearchParams {
    const ONE_DAY_IN_MILLIS = 86400000;
    return {
        endTime: new Date(),
        startTime: new Date(Date.now() - ONE_DAY_IN_MILLIS * 7),
        filters: {
            status: ["succeeded", "failed"],
            eInvPartnerId: [],
            channel: [],
            documentType: [],
            errorCode: [],
            mode: [],
            countryCode: [],
            supplier: []
        }
    }
}