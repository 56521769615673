import { ColumnExtended } from './ColumnExtended';
import { CompletedSolidIcon, StatusErrorFullIcon, SyncStatusSolidIcon, AlertSolidIcon, Blocked2SolidIcon } from '@fluentui/react-icons-mdl2';


const DASHBOARD_MIN_COLUMN_WIDTH = 80;

export const DashboardColumns: ColumnExtended[] = [
    {
        key: 'documentNumber',
        name: 'Document Number',
        fieldName: 'documentId',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH * 2,
    },
    {
        key: 'documentType',
        name: 'Document Type',
        fieldName: 'documentType',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH,
    },
    {
        key: 'country',
        name: 'Country',
        fieldName: 'countryCode',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH,
    },
    {
        key: 'company',
        name: 'Company Code',
        fieldName: 'companyCode',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH,
    },
    {
        key: 'partner',
        name: 'Calling Service',
        fieldName: 'eInvPartnerId',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH * 2
    },
    {
        key: 'receivedTime',
        name: 'Received Time',
        fieldName: 'startEventTime',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH * 2,
        isSorted: true,
        isSortedDescending: true,
        isSortable: true

    },
    {
        key: 'completedTime',
        name: 'Completed Time',
        fieldName: 'endEventTime',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH * 2,
        isSortable: true
    },
    {
        key: 'status',
        name: 'Status',
        fieldName: 'status',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH / 2,
        onRender: (item) => {
            if (item.status === 'Failed')
                return <StatusErrorFullIcon style={{ color: 'red' }} />;
            else if (item.status === 'Succeeded')
                return <CompletedSolidIcon style={{ color: 'green' }} />;
            else if (item.status === 'In Progress')
                return <SyncStatusSolidIcon style={{ color: 'blue' }} />;
            else if (item.status === 'Warning')
                return <AlertSolidIcon style={{ color: 'orange' }} />;
            else if (item.status === 'Cancel Succeeded')
                return <Blocked2SolidIcon style={{ color: 'green' }} />;
            else if (item.status === 'Cancel Failed')
                return <Blocked2SolidIcon style={{ color: 'red' }} />;
            else if (item.status === 'Cancel In Progress')
                return <Blocked2SolidIcon style={{ color: 'blue' }} />;
            return null;
        }
    },
    {
        key: 'extReceiptId',
        name: 'Ext. Receipt Id',
        fieldName: 'extReceiptId',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH * 5,
    },
    {
        key: 'internalInvoiceNumber',
        name: 'Internal Invoice Number',
        fieldName: 'invoiceNumber',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH * 2,
    },
    {
        key: 'supplier',
        name: 'Supplier',
        fieldName: 'supplier',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH,
    },
    {
        key: 'scenario',
        name: 'Scenario',
        fieldName: 'scenario',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH
    },
    {
        key: 'channel',
        name: 'Channel',
        fieldName: 'channel',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH
    },
    {
        key: 'salesMotion',
        name: 'Sales Motion',
        fieldName: 'salesMotion',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH
    },
    {
        key: 'documentGenerationDate',
        name: 'DocumentGenerationDate',
        fieldName: 'documentGenerationDate',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH * 2
    },
    {
        key: 'errorCode',
        name: 'Error Code',
        fieldName: 'errorCode',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH,
    },
    {
        key: 'mode',
        name: 'Mode',
        fieldName: 'mode',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH
    },
    {
        key: 'numberOfSubmissions',
        name: 'Number of Submissions',
        fieldName: 'numberOfSubmissions',
        isResizable: true,
        minWidth: DASHBOARD_MIN_COLUMN_WIDTH
    }
]
