import { useMsal } from '@azure/msal-react';
import { CoherenceLoading, ErrorStateComponent } from '@coherence-design-system/controls';
import React, { useEffect, useState } from 'react';
import { constructGetRequest, constructReportsUrl } from '../../controller/dashboardController';
import Configuration from '../../config/einvoicing-configuration';
import { AuthenticationResult } from '@azure/msal-common';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import './reports.css'
import { LeakageReport } from './LeakageReport';
import { DataVizPalette, getColorFromToken, GroupedVerticalBarChart } from '@fluentui/react-charting';
import { CountryCodeToFriendlyNameMapping } from '../../utils/FriendlyNameMappings';
import {
    Button,
    MessageBar,
    MessageBarTitle,
    MessageBarBody,
    MessageBarActions,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
interface ReportProps { }


function Reports(props: ReportProps) {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState('');
    const [mode, setMode] = useState('default');
    const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(true);
    const [isError, setIsError]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);
    const [reports, setReports]: [any[], React.Dispatch<React.SetStateAction<any>>] = useState<any[]>([]);
    const [hideMessage, setHideMessage]: [{ [key: string]: boolean }, React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>] = useState<{ [key: string]: boolean }>({
        'FailedEventsMapper': true,
        "SLAMapper": true,
        "SLAApproachingMapper": true
    });
    const [message, setMessage]: [{ [key: string]: string }, React.Dispatch<React.SetStateAction<{ [key: string]: string }>>] = useState<{ [key: string]: string }>({
        'FailedEventsMapper': '',
        "SLAMapper": '',
        "SLAApproachingMapper": ''
    });

    async function getAccessToken() {
        if (accessToken === '') {
            const request = {
                ...Configuration.msalTokenRequest,
                account: accounts[0]
            };
            const result: AuthenticationResult = await instance.acquireTokenSilent(request);
            setAccessToken(result.idToken);
            return result.idToken
        }
        return accessToken;
    }

    const colors: { [key: string]: string } = {
        "HU": getColorFromToken(DataVizPalette.color1),
        "IN": getColorFromToken(DataVizPalette.color2),
        "IT": getColorFromToken(DataVizPalette.color3),
        "RS": getColorFromToken(DataVizPalette.color4),
        "FI": getColorFromToken(DataVizPalette.color5),
        "NO": getColorFromToken(DataVizPalette.color6),
        "DK": getColorFromToken(DataVizPalette.color7),
        "SE": getColorFromToken(DataVizPalette.color8),
        "DE": getColorFromToken(DataVizPalette.color9),
        "SA": getColorFromToken(DataVizPalette.color10),
        "US": getColorFromToken(DataVizPalette.color11),
        "BR": getColorFromToken(DataVizPalette.color12),
    }
    function useReports(mode: string) {
        useEffect(() => {
            const reportsUrl: string = constructReportsUrl(Configuration.eventServiceUrl, mode);
            async function startLatency() {
                if (!ignore) {
                    try {
                        const requestParams = constructGetRequest(await getAccessToken());
                        const result = await fetch(reportsUrl, requestParams);

                        const reports: any[] = []
                        if (result !== undefined && result.ok) {
                            const resultJson: LeakageReport = await result.json()
                            if ('FailedEventsMapper' in resultJson && resultJson['FailedEventsMapper'] !== null && resultJson['FailedEventsMapper'] !== undefined) {

                                addReports(resultJson, reports, 'FailedEventsMapper');
                            }
                            if ('SLAMapper' in resultJson && resultJson['SLAMapper'] !== null && resultJson['SLAMapper'] !== undefined) {

                                addReports(resultJson, reports, 'SLAMapper');
                            }
                            if ('SLAApproachingMapper' in resultJson && resultJson['SLAApproachingMapper'] !== null && resultJson['SLAApproachingMapper'] !== undefined) {

                                addReports(resultJson, reports, 'SLAApproachingMapper');
                            }
                            setReports(reports)
                            setIsLoading(false);
                        }
                        else {
                            setIsLoading(false);
                            setIsError(true);
                        }
                    }
                    catch {
                        setIsLoading(false);
                        setIsError(true);
                    }
                }
            }

            let ignore = false;
            startLatency();
            return () => {
                ignore = true;
            }
        }, [mode]);
    }

    useReports(mode);



    const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item) {
            let modeVal = `${item.key}`;
            setMode(modeVal);
        }
    };

    const modeOptions: IDropdownOption[] = [
        {
            key: 'default',
            text: 'Live'
        },
        {
            key: 'uat',
            text: 'UAT'
        },
        {
            key: 'test',
            text: 'Test'
        }
    ];

    if (isLoading) {
        return <CoherenceLoading primaryText='Loading Data...' />
    }
    else if (!isError) {

        return (
            <div>
                <h1>Leakage Reports</h1>
                <div className='reports-container'>
                    <div className='reports-dropdown-container'>
                        <Dropdown className="reports-dropdown" label='Mode' onChange={(ev, it) => onDropdownChange(ev, it)} options={modeOptions} dropdownWidth='auto' selectedKey={mode} />
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <h4>Failed Transactions in Past 1 month</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <GroupedVerticalBarChart
                        chartTitle="Failed Transactions in Past 1 month"
                        data={reports[0]}
                        height={300}
                        isCalloutForStack={false}
                        barwidth={50}
                        hideLabels={false}
                        chartLabel="Failed Transactions in Past 1 month"
                    />
                </div>
                {!hideMessage['FailedEventsMapper'] && < div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <MessageBar shape="square" layout="multiline">
                        <MessageBarBody>
                            <MessageBarTitle>Failed Document Ids - </MessageBarTitle>
                            {message['FailedEventsMapper']}
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    aria-label="dismiss"
                                    appearance="transparent"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        hideMessage['FailedEventsMapper'] = true;
                                        setHideMessage({
                                            ...hideMessage, 'FailedEventsMapper': true
                                        });
                                    }}
                                />
                            }
                        >
                        </MessageBarActions>
                    </MessageBar>
                </div>}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <h4>SLA Breached Transactions in Past 1 month</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <GroupedVerticalBarChart
                        chartTitle="SLA Breached Transactions in Past 1 month"
                        data={reports[1]}
                        height={300}
                        isCalloutForStack={false}
                        barwidth={50}
                        hideLabels={false}
                        chartLabel="SLA Breached Transactions in Past 1 month"
                    />
                </div>

                {!hideMessage['SLAMapper'] && < div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <MessageBar shape="square" layout='multiline'>
                        <MessageBarBody>
                            <MessageBarTitle>SLA Breached Document Ids - </MessageBarTitle>
                            {message['SLAMapper']}
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    aria-label="dismiss"
                                    appearance="transparent"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        hideMessage['SLAMapper'] = true;
                                        setHideMessage({
                                            ...hideMessage, 'SLAMapper': true
                                        });
                                    }}
                                />
                            }
                        >
                        </MessageBarActions>
                    </MessageBar>
                </div>}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <h4>SLA Approaching Inprogress Transactions in Past 1 month</h4>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <GroupedVerticalBarChart
                        chartTitle="SLA Approaching Inprogress Transactions in Past 1 month"
                        data={reports[2]}
                        height={300}
                        isCalloutForStack={false}
                        barwidth={50}
                        hideLabels={false}
                        chartLabel="SLA Approaching Inprogress Transactions in Past 1 month"
                    />
                </div>

                {!hideMessage['SLAApproachingMapper'] && < div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
                    <MessageBar shape="square" layout='multiline'>
                        <MessageBarBody>
                            <MessageBarTitle>SLA Approaching Document Ids - </MessageBarTitle>
                            {message['SLAApproachingMapper']}
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    aria-label="dismiss"
                                    appearance="transparent"
                                    icon={<DismissRegular />}
                                    onClick={() => {
                                        setHideMessage({
                                            ...hideMessage, 'SLAApproachingMapper' : true });
                                    }}
                                />
                            }
                        >
                        </MessageBarActions>
                    </MessageBar>
                </div>}
            </div>
        );
    }
    else {
        return (
            <ErrorStateComponent
                callToActionButton={{
                    ctaAriaLabel: 'Click here to refresh the page',
                    ctaHandler: function noRefCheck() {
                        window.location.reload();
                    },
                    ctaText: 'Refresh page'
                }}
                descriptionText="Check your internet connection, try refreshing the page or contact support."
                headingLevel="h2"
                headingText="There was an issue loading the page"
            />);
    }

    function addReports(resultJson: LeakageReport, reports: any[], reportName: string) {
        const reportData: any = [];
        for (let date in resultJson[reportName]) {
            let series = [];
            for (let document of resultJson[reportName][date]) {
                series.push(
                    {
                        key: document.countryCode,
                        data: document.value > 100 ? 100 : document.value,
                        legend: CountryCodeToFriendlyNameMapping[document.countryCode],
                        color: colors[document.countryCode],
                        xAxisCalloutData: date,
                        yAxisCalloutData: document.value,
                        onClick: function setInfoMessage() {
                            setHideMessage({
                                ...hideMessage, [document.documentType]: false
                            });
                            setMessage({
                                ...message, [document.documentType]: document.documentIds.join(", ")
                            });
                        }
                    }
                );
            }
            reportData.push({
                name: date,
                series: series
            });
        }
        reports.push(reportData);
    }
}

export default Reports;